input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
html {
  font-size: 10px;
  background: #eee;
  min-height: 100vh;
  position: relative;
}
body {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}
input {
  background: none;
  border: none;
  padding: unset;
}
* {
  box-sizing: border-box;
}
