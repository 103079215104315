#root {
  position: relative;
  min-height: 100vh;
  --swiper-theme-color: #e6ce8d;
  user-select: none;
}
.image-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 2rem;
  text-align: center;
  background: #eee;
  color: #666;
}
.am-modal.help-modal.am-modal-transparent {
  width: 85vw;
}
.help-modal-content {
  padding: 0 1rem;
  line-height: 1.4rem;
  white-space: pre-wrap;
  text-align: left;
}
.am-modal.modal-nestle-back {
  background-color: transparent;
  background-image: url(https://biruimg.picbling.cn/nestle/dachu/modal_back_1.min.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.am-modal.modal_background2 {
  background-color: transparent;
  background-image: url(https://biruimg.picbling.cn/nestle/dachu/modal_background2.min.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.am-modal.modal_background2 .am-modal-content {
  background-color: inherit;
}
.am-modal.modal_back_long {
  background-color: transparent;
  background-image: url(https://biruimg.picbling.cn/nestle/dachu/modal_back_long.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.am-modal.modal_back_long .am-modal-content {
  background-color: inherit;
}
.am-modal.modal-nestle-back .am-modal-content {
  background-color: inherit;
}
.bg1 {
  background-color: #1f3d62;
  background-image: url("https://static.picbling.cn/4eb9377c-66b8-4144-941d-f7c4290326a1.png");
  background-size: cover;
  min-height: 100vh;
}
.bg1.bg-bottom {
  background-position: bottom;
}
.bg2 {
  background-color: #1f3d62;
  background-image: url("https://static.picbling.cn/5c15f2b2-9cee-4e45-8b8e-576417e28a07.png");
  background-size: cover;
  min-height: 100vh;
}
.bg2.bg-bottom {
  background-position: bottom;
}
.gradient-text {
  background-image: -webkit-linear-gradient(-4deg, #c39b70, #ebc5a8);
  background-clip: text;
  -webkit-background-clip: text;
  color: #ebc5a8;
}
/*.gradient-text-dark{*/
/*  background-image:-webkit-linear-gradient(-4deg,#c39b70,#ebc5a8);*/
/*  background-clip:text;*/
/*  -webkit-background-clip:text;*/
/*  text-fill-color:transparent;*/
/*  -webkit-text-fill-color:transparent;*/
/*}*/
.share-modal-text {
  color: #654831;
  font-size: 4vw;
  line-height: 7vw;
  padding: 6vw 0;
  font-weight: bold;
}
.am-modal.share-notify-modal {
  width: 85vw;
}
.poster-collection {
  position: relative;
  margin: 0 auto 2rem auto;
  padding: 0 5vw;
}
.poster-collection-items {
  display: flex;
  justify-content: space-between;
}
.poster-collection-item {
  position: relative;
  width: 15vw;
  height: 20vw;
}
.poster-collection-item.is_active {
  box-shadow: 0 0 10px 4px rgba(224, 185, 153, 0.66);
}
.poster-collection-item-icon {
  width: 100%;
  height: 100%;
}
.poster-collection-item-badge {
  position: absolute;
  width: 2rem;
  height: 2rem;
  background: #936a48;
  border-radius: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 1.4rem;
  color: #fff;
  top: -1rem;
  right: -1rem;
}
.view-detail-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.7);
}
.swiper-poster {
  width: 100%;
}
.swiper-item-poster {
  width: 70vw;
  height: 60vh;
  background: #333;
  position: relative;
  margin: auto;
}
.poster-close-btn {
  color: #977d5b;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 4rem;
}
.poster-swiper-container {
  position: absolute;
  width: 100%;
  top: 10vh;
}
.share-modal-cards .poster-collection-item {
  position: absolute;
}
.poster-collection-item-3 {
  top: 0;
  left: 0;
}
.poster-collection-item-4 {
  top: 0;
  right: 0;
}
.poster-collection-item-2 {
  bottom: 0;
  left: 0;
}
.poster-collection-item-1 {
  bottom: 0;
  right: 0;
}
.poster-collection-item-5 {
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
}
.share-modal-cards {
  position: relative;
  height: 55vw;
  width: 55vw;
  margin: auto;
}
.share-help-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}
.share-help-mask .share-text-arrow {
  position: absolute;
  width: 50vw;
  top: 2rem;
  right: 2rem;
}
.ghost-button-back {
  font-weight: 800;
  color: #7c5637;
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 47px;
  line-height: 47px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  border: 2px solid #7c5637;
  border-radius: 47px;
}
.touch-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3) !important;
}
.touch-highlight:hover,
.touch-highlight:active {
  opacity: 0.8;
}
.help-modal-a {
  position: absolute;
  height: 10vw;
  width: 90%;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
}
