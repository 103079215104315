.page-index {
  text-align: center;
  position: relative;
  .post-container {
    width: 100%;
    padding-bottom: 1rem;
  }
}


//.video-container {
//  width: 100%;
//  height: 57vw;
//  border-top: 2px solid #f1dbc6;
//  border-bottom: 2px solid #f1dbc6;
//  background: #333;
//
//  video {
//    width: 100%;
//    height: 100%;
//  }
//}

.index-banner {
  img {
    width: 66vw;
  }
}

.index-title2,
.index-prize,
.index-poster {
  width: 100%;
  padding: 0 2rem;
}

img.index-title2 {
  width: 63vw;
  padding-top: 3vw;
}

img.index-title3 {
  height: 15vw;
  margin-top: 6vw;
  margin-bottom: 5vw;
}

.index-text {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 2rem;
  letter-spacing: 0;
  color: #906a40;
}

.index-button-wrap {
  padding: 4rem 0;
  text-align: center;
  width: 80vw;
  margin: auto;
  position: relative;
}

.index-logo {
  display: flex;
  justify-content: space-between;
  padding: 3vw 4vw 6vw 4vw;
  position: relative;

  .logo-meiji {
    height: 14vw;
  }

  .help-link {
    color: #f2c4a0;
    position: absolute;
    // top: 10vw;
    font-size: 3vw;
    border-bottom: 1px solid #f2c4a0;
    right: 20vw;
    font-weight: bold;
  }

  .logo-nestle {
    height: 14vw;
  }
}

.rule-line {
  font-size: 1rem;
  color: #694e36;
  line-height: 2rem;
}

.rule-title {
  font-weight: bold;
  font-size: 5vw;
  color: #654831;
  padding: 3rem 0;
  text-align: center;
}

.index-pointer {
  width: 10vw;
  bottom: 10vw;
  right: 2vw;
  z-index: 2;
  position: absolute;
  animation: fade_in_and_out ease infinite 2s;
}

.arrow-down{
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  width: 10vw;
  bottom: 2vw;
  animation: fade_in forwards .4s;
  .arrow-down-icon{
    display: block;
    width:10vw;
    animation: up_and_down infinite 1s;
  }
}

.video-container {
  display: block;
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  overflow: hidden;
}

.start-btn {
  width: 60vw;
  /* margin: auto; */
  bottom: 1%;
  left: 20%;
  position: absolute;
  z-index: 100000;
}

.play-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.play-text {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  width: 120px;
}

.play-btn {
  position: absolute;
  top: 50px;
  left: 50px;
  /* margin-top: -10vw; */
  /* margin-left: -6vw; */
  width: 80px;
  z-index: 2;
}

.start-btn-animation {
  animation:btnShine 1s infinite;
  -webkit-animation:btnShine 1s infinite; /*Safari and Chrome*/
}

@keyframes btnShine
{
  0%{
    transform: scale(1);  /*开始为原始大小*/
  }
  25%{
    transform: scale(1.1); /*放大1.1倍*/
  }
  50%{
    transform: scale(1);
  }
  75%{
    transform: scale(1.1);
  }

}

@-webkit-keyframes btnShine /*Safari and Chrome*/
{
  0%{
    transform: scale(1);  /*开始为原始大小*/
  }
  25%{
    transform: scale(1.1); /*放大1.1倍*/
  }
  50%{
    transform: scale(1);
  }
  75%{
    transform: scale(1.1);
  }
}
