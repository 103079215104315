.page-my {
    text-align: center;
    background-color: #333;
    min-height: 100vh;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    overflow-x: hidden;
}

.my-desc {
    color: #fff;
    text-align: left;
    font-size: 2rem;
}

.desc-input {
    display: inline-block;
    text-align: left;
    width: calc(100% - 6rem);
}

.desc-input input {
    width: 100%;
    height: 100%;
    text-align: left;
    color: #e5bfa0;
}

.rest-count {
    color: #C59D73;
    text-shadow: 0px 0px 3px #7f6a56;
    line-height: 8vw;
}

.title-collection {
    color: #C59D73;
    text-shadow: 0 0 3px #7f6a56;
    width: 100%;
}


.post-text-container {
    background-image: url("https://biruimg.picbling.cn/nestle/dachu/text_container.png");
    background-size: 100% 100%;
    color: #fff;
    padding: 0rem 7rem;
    text-align: left;
    line-height: 1.6;
    min-height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 46vw;
    font-size: 1.8rem;
    font-weight: bold;
    word-break: break-all;
}

.swiper-container-of-text {
    margin: 2rem 0;
}

.my-desc-line {
    border-bottom: 1px solid #a0845a;
    line-height: 10vw;
    white-space: nowrap;
}

/*.am-button.am-button-primary.btn-gen-post {*/
/*    margin: 2rem 0;*/
/*    font-size: 2.4rem;*/
/*    padding: 0 5rem;*/
/*    font-weight: 800;*/
/*    color: #654831;*/
/*}*/

.commit-btn {
    width: 60vw;
    /* margin: auto; */
    bottom: 1%;
    left: 20%;
    position: absolute;
}

.bg-post {
    /*position: absolute;*/
    /*top: 3%;*/
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
}

.bg-title {
    position: absolute;
}

img.text_scroll_help {
    height: 14vw;
    margin-bottom: 4vw;
}

img.button_edit_text {
    height: 12vw
}

img.button-view-template {
    height: 12vw
}


.file_container {
    background-image: url("https://static.picbling.cn/e757a8b7-9a68-4fee-a06e-720f3c4989cd.png");
    background-size: cover;
    width: 347px;
    height: 254px;
    display: inline-block;
    position: relative;
    /*margin: 0 20px;*/
    overflow: hidden;
}

.file_container input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

/*.file_container .image_preview {*/
/*    height: 100%;*/
/*    width: auto;*/
/*}*/

.file_container:after {
    /*content: "+";*/
    font-size: 40px;
    line-height: 30vw;
    text-align: center;
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.row1 {
    text-align: center;
    position: absolute;
    top: 13%;
    left: 0;
    right: 0;
    margin: auto;
}

.img-mask {
    width: 326px;
    /* z-index: 99; */
    position: absolute;
    left: 5px;
    top: 7px;
}

.img-preview {
    width: 200px;
    height: 150px;
    position: absolute;
    left: 84px;
    top: 86px;
}

.commit-btn-animation {
    animation:btnShine 1s infinite;
    -webkit-animation:btnShine 1s infinite; /*Safari and Chrome*/
}

@keyframes btnShine
{
    0%{
        transform: scale(1);  /*开始为原始大小*/
    }
    25%{
        transform: scale(1.1); /*放大1.1倍*/
    }
    50%{
        transform: scale(1);
    }
    75%{
        transform: scale(1.1);
    }

}

@-webkit-keyframes btnShine /*Safari and Chrome*/
{
    0%{
        transform: scale(1);  /*开始为原始大小*/
    }
    25%{
        transform: scale(1.1); /*放大1.1倍*/
    }
    50%{
        transform: scale(1);
    }
    75%{
        transform: scale(1.1);
    }
}
