/* component styles */

.am-button {
  border-radius: 47px;
  font-weight: bold !important;
  &.am-button-primary {
    border: none;
    font-weight: 500;
    color: #795548;
    background-color: #f4dc92;
    background-image: linear-gradient(0deg, #c39b70 0%, #d7b08c 34%, #ebc5a8 100%), linear-gradient(#000000, #000000);
    background-blend-mode: normal, normal;
  }

  &.am-button-ghost {
    background: none;
    color: #c39b70;
    border: 1px solid #c39b70 !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-primary::before {
    border: 1px solid #f4dc92 !important;
  }
}

/* #DEB894 */

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

  html:not([data-scale]) .am-button-ghost::before {
    border: 1px solid #f4dc92 !important;
  }
}

.am-picker-popup-item {
  color: #795548;
}

.am-modal-button-group-v .am-modal-button {
  color: #C49C6E;
}


@ghost-color1: #42362a;

.ghost-button-border {
  border: 2px solid @ghost-color1;
  width: 100%;
  display: inline-block;
  border-radius: 10vw;
  padding: 2px;
  box-sizing: border-box;
  line-height: 9vw;
  font-weight: bold;
  &.ghost-size-small {
    border-radius: 6vw;
    max-width: 26vw;
    line-height: 1;

  }

  .ghost-button {
    height: 10vw;
    width: 100%;
    color: @ghost-color1;
    border: 1px solid @ghost-color1;
    border-radius: 10vw;
    text-align: center;
    line-height: 10vw;
    display: inline-block;
    font-size: 5vw;
    letter-spacing: 1vw;
    cursor: pointer;

    &.ghost-size-small {
      height: 6vw;
      border-radius: 6vw;
      line-height: 6vw;
      font-size: 3vw;
      max-width: 26vw;
      border:none;
    }
  }
}
