@keyframes fade_in_and_out {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes up_and_down {
  0% {
    transform: translateY(-2px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
