@media screen and (min-width: 300px) and (max-width: 319px) {
  html {
    font-size: 8px
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  html {
    font-size: 9px
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  html {
    font-size: 10px
  }
}

@media screen and (min-width: 415px) and (max-width: 639px) {
  html {
    font-size: 12px
  }
}

/*魅族mx4*/
@media only screen and (width :384px) {
  html {
    font-size: 8px
  }
}

@media screen and (min-width: 320px) and (max-width: 320px) {

}


/*小华为*/
@media only screen and (width :360px) and (height: 519px) {
  html {
    font-size: 7px
  }
}


@media screen and (min-width: 640px) and (max-width: 719px) {
  html {
    font-size: 14px
  }
}

@media screen and (min-width: 720px) and (max-width: 749px) {
  html {
    font-size: 16px
  }
}

@media screen and (min-width: 750px) and (max-width: 799px) {
  html {
    font-size: 16px
  }
}

@media screen and (min-width: 800px) {
  html {
    font-size: 16px
  }
}
