
.page-post {
  padding-top: 1vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .post-container {
    width: 75%;
    margin: 1% auto;
    position: relative;

    .poster-image {
      width: 100%;
      //box-shadow: 1vw 1vw 4vw 0vw rgba(230, 162, 106, 0.8);
    }
  }

  .post-bottom-buttons {
    display: flex;
    justify-content: space-between;
    padding: 5vw 4vw;
  }

  .press-save-text {
    text-align: center;
    font-size: 4vw;
    line-height: 8vw;
  }

  .shop-btn {
    width: 70vw;
    /* margin: auto; */
    bottom: 1%;
    left: 15%;
    position: absolute;
  }

  .save-text {
    width: 50vw;
    /* margin: auto; */
    bottom: 15%;
    left: 25%;
    position: absolute;
  }



  .shop-btn-animation {
    animation:btnShine 1s infinite;
    -webkit-animation:btnShine 1s infinite; /*Safari and Chrome*/
  }

  @keyframes btnShine
  {
    0%{
      transform: scale(1);  /*开始为原始大小*/
    }
    25%{
      transform: scale(1.1); /*放大1.1倍*/
    }
    50%{
      transform: scale(1);
    }
    75%{
      transform: scale(1.1);
    }

  }

  @-webkit-keyframes btnShine /*Safari and Chrome*/
  {
    0%{
      transform: scale(1);  /*开始为原始大小*/
    }
    25%{
      transform: scale(1.1); /*放大1.1倍*/
    }
    50%{
      transform: scale(1);
    }
    75%{
      transform: scale(1.1);
    }
  }
}
