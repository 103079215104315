@import "reset.css";
@import "normalize.css";
@import "app.css";
/* component styles */
.am-button {
  border-radius: 47px;
  font-weight: bold !important;
}
.am-button.am-button-primary {
  border: none;
  font-weight: 500;
  color: #795548;
  background-color: #f4dc92;
  background-image: linear-gradient(0deg, #c39b70 0%, #d7b08c 34%, #ebc5a8 100%), linear-gradient(#000000, #000000);
  background-blend-mode: normal, normal;
}
.am-button.am-button-ghost {
  background: none;
  color: #c39b70;
  border: 1px solid #c39b70 !important;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-primary::before {
    border: 1px solid #f4dc92 !important;
  }
}
/* #DEB894 */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-ghost::before {
    border: 1px solid #f4dc92 !important;
  }
}
.am-picker-popup-item {
  color: #795548;
}
.am-modal-button-group-v .am-modal-button {
  color: #C49C6E;
}
.ghost-button-border {
  border: 2px solid #42362a;
  width: 100%;
  display: inline-block;
  border-radius: 10vw;
  padding: 2px;
  box-sizing: border-box;
  line-height: 9vw;
  font-weight: bold;
}
.ghost-button-border.ghost-size-small {
  border-radius: 6vw;
  max-width: 26vw;
  line-height: 1;
}
.ghost-button-border .ghost-button {
  height: 10vw;
  width: 100%;
  color: #42362a;
  border: 1px solid #42362a;
  border-radius: 10vw;
  text-align: center;
  line-height: 10vw;
  display: inline-block;
  font-size: 5vw;
  letter-spacing: 1vw;
  cursor: pointer;
}
.ghost-button-border .ghost-button.ghost-size-small {
  height: 6vw;
  border-radius: 6vw;
  line-height: 6vw;
  font-size: 3vw;
  max-width: 26vw;
  border: none;
}
@media screen and (min-width: 300px) and (max-width: 319px) {
  html {
    font-size: 8px;
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  html {
    font-size: 9px;
  }
}
@media screen and (min-width: 376px) and (max-width: 414px) {
  html {
    font-size: 10px;
  }
}
@media screen and (min-width: 415px) and (max-width: 639px) {
  html {
    font-size: 12px;
  }
}
/*魅族mx4*/
@media only screen and (width: 384px) {
  html {
    font-size: 8px;
  }
}
/*小华为*/
@media only screen and (width: 360px) and (height: 519px) {
  html {
    font-size: 7px;
  }
}
@media screen and (min-width: 640px) and (max-width: 719px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 720px) and (max-width: 749px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 750px) and (max-width: 799px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 800px) {
  html {
    font-size: 16px;
  }
}
@keyframes fade_in_and_out {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes up_and_down {
  0% {
    transform: translateY(-2px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
